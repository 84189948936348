import React from 'react'
import { Link } from 'gatsby'

const TagList = ({ tags, baseUri = `/blog/tags/` }) => (
  <ul className={`m-0 p-0`}>
    {tags.map((tag, index) => (
      <li className={`inline-block mr-1`} key={index}>
        <Link
          to={baseUri + tag.slug}
          className={`border border-blue rounded font-mono text-xs py-1 px-3 hover:text-blue-darker`}
        >
          {tag.title}
        </Link>
      </li>
    ))}
  </ul>
)

export default TagList
