import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/SEO'
import TagList from '../../components/TagList'

const BlogTag = props => {
  const tags = props.data.craftGql.tags

  return (
    <Layout>
      <SEO
        title={`Post Tags`}
        additionalMeta={[{ name: `robots`, content: `noindex,follow` }]}
      />
      <section role="main" className={`pt-4 mb-32`}>
        <h1
          className={`text-5xl font-bold my-12 max-w-md mx-auto leading-none`}
        >
          Post Tags
        </h1>
        <div className={`max-w-md mx-auto`}>
          <TagList tags={tags} />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    craftGql {
      tags(group: "blogTags", limit: 1000) {
        title
        slug
      }
    }
  }
`

export default BlogTag
